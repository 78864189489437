"use client";

import { Cart, ProductData } from "@/lib/5874/types";
import { OrderResponse } from "@/lib/bigcommerce-b2b/types";

import { createContext, useContext, useEffect } from "react";

// "as const" syntax is better and more reliable than enums
export const DataLayerEvents = {
	PAGE_VIEW: "page_view",
	VIEW_ITEM_LIST: "view_item_list",
	VIEW_SEARCH_RESULTS: "view_search_results",
	SELECT_ITEM: "select_item",
	VIEW_ITEM: "view_item",
	CART_OBJECT: "cart_object",
	ADD_TO_CART: "add_to_cart",
	ADD_TO_WISHLIST: "add_to_wishlist",
	REMOVE_FROM_WISHLIST: "remove_from_wishlist",
	VIEW_CART: "view_cart",
	REMOVE_FROM_CART: "remove_from_cart",
	BEGIN_CHECKOUT: "begin_checkout",
	ADD_SHIPPING_INFO: "add_shipping_info",
	ADD_PAYMENT_INFO: "add_payment_info",
	PURCHASE: "purchase",
	REGISTRATION: "registration",
	FORM_SUBMISSION: "form_submission",
	ACCOUNT_SUCCESS: "login_success",
	ACCOUNT_FAIL: "login_fail",
	LOGIN: "login",
	LOGOUT: "logout",
} as const;

type EventValues<T> = T[keyof T];
export type DEvents = EventValues<typeof DataLayerEvents>;

const formIdToFormName: Record<string, string> = {
	"752945a7-ec1a-43dc-92d8-659b19c0ee65": "contact_form",
	"477b33ec-5eed-45b4-aa31-3e5808530706": "footer_email_sign_up",
};

export type DataLayerProductData = {
	product: ProductData | undefined;
	quantity: number;
	variantId: number | undefined;
};

type CartType = {
	id: string;
	subTotal: number;
	currency: string;
};

type CartEventsType = {
	products: DataLayerProductData[];
	itemListId: string | number;
	itemListName: string;
	cart: CartType | undefined;
};

export type ChangeType = "decrease" | "increase" | "none";

interface ContextProps {
	push: (event: any) => void;
}

interface Props {
	children: React.ReactNode;
}

const Context = createContext({} as ContextProps);

export const DataLayerProvider = ({ children }: Props) => {
	// const GTM_ID = process.env.GTM_ID;
	const push = (event: any) => {
		const dataLayer = (window as any).dataLayer;

		if (!dataLayer) {
			console.info("DataLayer not found");
			return;
		}

		dataLayer.push(event);
	};

	const value = {
		push,
	};

	useEffect(() => {
		const pushFormSubmissionEvent = (event: MessageEvent<any>) => {
			if (
				event.data.type === "hsFormCallback" &&
				event.data.eventName === "onFormSubmitted"
			) {
				push({
					event: DataLayerEvents.FORM_SUBMISSION,
					formType: getFormName(event.data.id),
				});
			}
		};
		window.addEventListener("message", (e) => pushFormSubmissionEvent(e));
		return () => window.removeEventListener("message", pushFormSubmissionEvent);
	}, []);

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useDataLayerContext = () => {
	const { push } = useContext<ContextProps>(Context);

	// if (!context) {
	// 	throw new Error(
	// 		"useDataLayerContext must be used within a DataLayerProvider",
	// 	);
	// }

	const formSubmission = (formType: string) => {
		push({
			event: DataLayerEvents.FORM_SUBMISSION,
			formType,
		});
	};

	/**
	 * Fires click of product on a category list and product carousel
	 * @param products
	 * @param itemListId
	 * @param itemListName
	 */
	const selectItem = (
		product: ProductData,
		entityId: number | undefined,
		itemListId: string | number,
		itemListName: string | undefined,
		currencyCode: string,
	) => {
		const items = [
			convertProduct({
				product,
				quantity: 1,
				index: 0,
				entityId,
				itemListId,
				itemListName,
				currencyCode,
			}),
		];
		push({ ecommerce: null });
		push({
			event: DataLayerEvents.SELECT_ITEM,
			item_list_id: itemListId,
			item_list_name: itemListName ?? "",
			ecommerce: {
				items,
			},
		});
	};

	/**
	 * Fires load of the category page and product carousel
	 * @param products
	 * @param itemListId
	 * @param itemListName
	 */
	const viewItemList = (
		products: ProductData[],
		itemListId: string | number,
		currencyCode: string,
		itemListName?: string,
	) => {
		const items = products.map((product, index) =>
			convertProduct({
				product,
				quantity: 1,
				index,
				itemListId,
				itemListName,
				currencyCode,
			}),
		);
		push({ ecommerce: null });
		push({
			event: DataLayerEvents.VIEW_ITEM_LIST,
			item_list_id: itemListId,
			item_list_name: itemListName ?? "",
			ecommerce: {
				items,
			},
		});
	};

	const viewSearchResults = (
		results: ProductData[],
		searchTerm: string,
		itemListId: string | number,
		currencyCode: string,
		isLoggedIn: boolean,
		scope: "search_dropdown" | "search_results_page",
		itemListName?: string,
	) => {
		const items = results?.map((result, index) =>
			convertProduct({
				product: result,
				quantity: 1,
				index,
				itemListId,
				itemListName,
				currencyCode,
				isLoggedIn,
			}),
		);
		push({ params: null });
		push({
			event: DataLayerEvents.VIEW_SEARCH_RESULTS,
			params: {
				searchTerm,
			},
			ecommerce: {
				item_list_id: scope,
				item_list_name: toTitleCase(scope),
				items,
			},
		});
	};

	/**
	 * Fires load of the product page
	 * @param products
	 * @param itemListId
	 * @param itemListName
	 */
	const viewItem = (
		product: ProductData,
		itemListId: string | number,
		itemListName: string,
		currencyCode: string,
	) => {
		const items = [
			convertProduct({
				product,
				quantity: 1,
				index: 0,
				itemListId,
				itemListName: toTitleCase(itemListName),
				currencyCode,
			}),
		];

		const ecommerce = {
			items,
		};
		push({ ecommerce: null });
		push({
			event: DataLayerEvents.VIEW_ITEM,
			ecommerce,
		});
	};

	/**
	 * Fires when a product is added to the cart (From anywhere and/or increase quantity in cart)
	 * @param products
	 * @param quantity
	 * @param currency
	 * @param itemList
	 */
	const addToCart = (args: CartEventsType) => {
		const validProducts = args.products.filter(
			(product) => product.product !== undefined,
		);
		const items = validProducts.map((product, index) => {
			const item = convertProduct({
				product: product.product,
				entityId: product.variantId,
				quantity: product.quantity,
				index,
				itemListId: args.itemListId,
				itemListName: args.itemListName,
				currencyCode: args.cart?.currency,
			});

			return {
				...item,
			};
		});

		const ecommerce = {
			currency: args.cart?.currency,
			value: args.cart?.subTotal,
			items,
		};
		push({ ecommerce: null });
		push({
			event: DataLayerEvents.ADD_TO_CART,
			ecommerce,
		});
	};

	/**
	 * Fires on when a user removes something from the cart (From anywhere & decreases quantity in the cart)
	 * @param cart
	 * @param triggeredFrom
	 * @param product
	 * @param quantity
	 * @param currency
	 * @param itemList
	 */
	const removeFromCart = (args: CartEventsType) => {
		const items = args.products.map((product, index) => {
			const item = convertProduct({
				...product,
				index,
				itemListId: args.itemListId,
				itemListName: args.itemListName,
				currencyCode: args.cart?.currency,
				specialCase: true,
			});

			return {
				...item,
			};
		});

		const ecommerce = {
			currency: args.cart?.currency,
			value: args.cart?.subTotal,
			items,
		};

		push({ ecommerce: null });
		push({
			event: DataLayerEvents.REMOVE_FROM_CART,
			ecommerce,
		});
	};

	/**
	 * Fires when a product is added to the wishlist
	 * @param products
	 * @param itemListId
	 * @param itemListName
	 */
	const addToWishlist = (
		product: ProductData,
		quantity: number,
		entityId: number | undefined,
		itemListId: string | number,
		itemListName: string | undefined,
		currencyCode: string,
	) => {
		const items = [
			convertProduct({
				product,
				quantity: 1,
				index: 0,
				entityId,
				itemListId,
				itemListName,
				currencyCode,
			}),
		];
		push({ ecommerce: null });
		push({
			event: DataLayerEvents.ADD_TO_WISHLIST,
			ecommerce: {
				currency: currencyCode,
				value: ((quantity ?? 1) * product.pricesExTax.basePrice.value).toFixed(
					2,
				),
				items,
			},
		});
	};

	/**
	 * Fires on when a user views the cart (Cart page & mini cart)
	 * @param cart
	 * @param products
	 */
	const viewCart = (cart: Cart, products: DataLayerProductData[]) => {
		const validProducts = products.filter(
			(product) => product.product !== undefined,
		);
		const items = validProducts?.map((product, index) =>
			convertProduct({ ...product, index, currencyCode: cart.currency.code }),
		);

		const ecommerce = {
			currency: cart.currency.code,
			value: cart.base_amount,
			items,
		};

		push({ ecommerce: null });
		push({
			event: DataLayerEvents.VIEW_CART,
			ecommerce,
		});
	};

	/**
	 * Fires on checkout page load
	 * @param cart
	 * @param products
	 */
	const beginCheckout = (cart: Cart, products: DataLayerProductData[]) => {
		const items = products.map((product, index) =>
			convertProduct({ ...product, index }),
		);

		const ecommerce = {
			currency: cart.currency.code,
			coupon: cart.coupons.length > 0 ? cart.coupons.at(0) : "",
			value: cart.cart_amount,
			items,
		};

		push({ ecommerce: null });
		push({
			event: DataLayerEvents.BEGIN_CHECKOUT,
			ecommerce,
		});
	};

	/**
	 * Fires on when the user completes the shipping step in the checkout
	 * @param cart
	 * @param products
	 */
	const addShippingInfo = (cart: Cart, products: DataLayerProductData[]) => {
		const items = products.map((product, index) =>
			convertProduct({ ...product, index }),
		);

		const ecommerce = {
			currency: cart.currency.code,
			coupon: cart.coupons.length > 0 ? cart.coupons.at(0) : "",
			value: cart.cart_amount,
			shipping_tier: "", // TODO: add shipping tier info
			items,
		};

		push({ ecommerce: null });
		push({
			event: DataLayerEvents.ADD_SHIPPING_INFO,
			ecommerce,
		});
	};

	/**
	 * Fires on when the user completes the shipping step in the checkout
	 * @param cart
	 * @param products
	 */
	const addPaymentInfo = (cart: Cart, products: DataLayerProductData[]) => {
		const items = products.map((product, index) =>
			convertProduct({ ...product, index }),
		);

		const ecommerce = {
			currency: cart.currency.code,
			coupon: cart.coupons.length > 0 ? cart.coupons.at(0) : "",
			value: cart.cart_amount,
			payment_type: "", // TODO: add payment info
			items,
		};

		push({ ecommerce: null });
		push({
			event: DataLayerEvents.ADD_PAYMENT_INFO,
			ecommerce,
		});
	};

	/**
	 * Fires on when the user completes the shipping step in the checkout
	 * @param order
	 */
	const purchase = (order: OrderResponse) => {
		const ecommerce = {
			transaction_id: "", // Todo
			value: order.total_ex_tax,
			tax: order.total_tax,
			shipping: order.shipping_cost_inc_tax,
			currency: order.currency_code,
			coupon: order.coupons.resource, // todo ??
			urs_info: {
				// em: MD5(order.consignments.shipping?.[0].email),
				// ph: MD5(order.consignments.shipping?.[0].phone),
				// fn: MD5(order.consignments.shipping?.[0].firstName),
				// ls: MD5(order.consignments.shipping?.[0].lastName),
				// zip: MD5(order.consignments.shipping?.[0].postalCode),
			},
			// items: mapItems(order.lineItems.physicalItems, order.currency.code, true),
		};

		push({ ecommerce: null });
		push({
			event: DataLayerEvents.PURCHASE,
			ecommerce,
		});
	};

	return {
		push,
		formSubmission,
		viewItemList,
		selectItem,
		viewSearchResults,
		viewItem,
		addToCart,
		removeFromCart,
		addToWishlist,
		viewCart,
		beginCheckout,
		addShippingInfo,
		addPaymentInfo,
		purchase,
	};
};

const getFormName = (formId: string) => {
	return formIdToFormName[formId] ?? formId;
};

const convertProduct = (attributes: {
	product: ProductData | undefined;
	quantity: number;
	index: number;
	entityId?: number;
	itemListId?: string | number;
	itemListName?: string | undefined;
	currencyCode?: string;
	isLoggedIn?: boolean;
	specialCase?: boolean;
}) => {
	const {
		product,
		quantity,
		index,
		entityId,
		itemListId,
		itemListName,
		currencyCode,
		isLoggedIn,
		specialCase,
	} = attributes;

	if (!product) {
		console.warn("convertProduct called with undefined product");
		return null;
	}

	const variant = entityId
		? product.variants?.find((variant) => variant.entityId === entityId)
		: undefined;

	const price = variant ? variant.pricesExTax : product.pricesExTax;

	if (!price) {
		return;
	}

	let discount = 0;

	if (price.salePrice && price.basePrice > price.salePrice) {
		discount = price.basePrice.value - price.salePrice.value;
	}

	const reportingCategory = findCategory(product);

	const item = {
		item_id: product.id,
		item_sku: product.sku,
		item_name: product.name,
		currency: currencyCode,
		...(isLoggedIn ? { discount: toFixed(discount) } : {}),
		index,
		item_category: reportingCategory,
		item_brand: product.brand?.name || "",
		item_list_id: itemListId || "",
		item_list_name: itemListName ? itemListName : itemListId || "",
		item_variant: variant?.sku || "",
		price: price.price.value,
		quantity: quantity,
	};

	if (specialCase) {
		const { item_list_id, item_list_name, ...rest } = item;
		return rest;
	}

	return item;
};

const toFixed = (value: number) => {
	try {
		return parseFloat(value.toFixed(2));
	} catch {
		return value;
	}
};

const findCategory = (product: ProductData | undefined): string => {
	if (!product) {
		return "";
	}

	return product.categories?.[0]?.name ?? "";
};

const toTitleCase = (str: string): string => {
	return str
		.toLowerCase()
		.replace(/_/g, " ")
		.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const getItemListData = (product: ProductData | undefined) => {
	if (product === undefined) return { itemListId: "", itemListName: "" };

	const breadcrumbs = product?.categories?.[0]?.breadcrumbs || [];

	const itemListId = breadcrumbs.length
		? breadcrumbs[breadcrumbs.length - 1].entityId.toString()
		: "";

	const itemListName = breadcrumbs.length
		? breadcrumbs[breadcrumbs.length - 1].name
		: "";

	return { itemListId, itemListName };
};

export const getCartDatalayerDetails = (
	cart: Cart | undefined,
): CartType | undefined => {
	if (!cart) {
		return undefined;
	}

	return {
		id: cart.id,
		subTotal: cart.cart_amount,
		currency: cart.currency.code,
	};
};
